import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { toast } from 'react-toastify'
import { fetchChannels } from '../../../utils/channelUtils'

const SendInstructions = ({ onClose }) => {
  const guildId = localStorage.getItem('guildId')
  const animatedComponents = makeAnimated()
  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption)
  }

  const sendInstructions = () => {
    if (!selectedChannel) {
      toast.error('Please select a channel.')
      return
    }
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/channels/send-message/holder-verification`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          channelId: selectedChannel.value,
          guildId: localStorage.getItem('guildId'),
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        toast.success('Instructions sent successfully!')
        setSelectedChannel(null)
      })
      .catch((error) => {
        console.error('Error sending instructions:', error)
        toast.error('Error sending instructions.')
      })
  }

  useEffect(() => {
    if (selectedChannel) {
      sendInstructions()
    }
  }, [selectedChannel])

  return (
    <>
      {/* Select Channel */}
      <div className="w-full  flex flex-col">
        <span className="text-sm">Select a Channel</span>
        <div className="w-full  flex flex-col bg-second rounded-lg border border-third">
          <Select
            isLoading={isLoading}
            isMulti={false}
            options={dropdownChannels}
            value={selectedChannel}
            onChange={handleChannelChange}
            placeholder="Select channel"
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
            styles={{
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#2C2F48',
                color: '#fff',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                height: 'auto',
                maxHeight: '100%',
              }),
              container: (provided, state) => ({
                ...provided,
                margin: 0,
                padding: 0,
                border: 0,
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#fff',
              }),
              control: (provided, state) => ({
                ...provided,
                maxHeight: '100%',
                maxWidth: '100%',
                backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                color: '#fff',
                borderRadius: 8,
                borderColor:
                  state.isFocused || state.isHovered ? '#9945ff' : '#2C2F48',
                boxShadow:
                  state.isFocused || state.isHovered
                    ? '0 0 0 0.5px #9945ff'
                    : 'none',
                paddingLeft: '1rem',
                '&:hover': {
                  borderColor: '#9945ff',
                  boxShadow: '0 0 0 0.5px #9945ff',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#9945ff'
                  : state.isFocused
                    ? '#9945ff'
                    : '#2C2F48',
                color: '#fff',
                cursor: 'pointer',
                padding: '0 1rem',
                margin: 0,
              }),
              multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#9945ff',
                color: '#fff',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: '#fff',
              }),
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SendInstructions
