import React from 'react'
import adBanner from '../../../img/AdBanner/TekrosSpeaker.png'

const AdBanner = () => {
  return (
    <div className='w-full h-32 bg-main/20 rounded-lg p-5 flex shadow-lg shadow-shadow items-center'>
      <img src={adBanner} alt="speaker" className='absolute hidden lg:block bottom-0 right-0 h-36' />
      <div className='flex flex-col gap-y-2 justify-center items-center w-full'>
        <span className='flex tekros font-normal lg:text-sm text-xs w-[90%] text-center'>
          Protect your Discord server with our powerful global ban utility! With over 600 flagged bad actors, adding our bot instantly safeguards your community.</span>
        <span className='flex tekros font-normal text-xs lg:text-sm text-center'>
          Don't wait—secure your server today
        </span>
      </div>
    </div>
  )
}

export default AdBanner