import React, { useState, useEffect } from 'react'
import { Button, IconButton } from '@material-tailwind/react'
import { BiEdit } from 'react-icons/bi'
import { BsTelegram } from 'react-icons/bs'
import { BsTwitterX } from 'react-icons/bs'
import { InputDefault } from '../../../admin/components/ui/input'
import { HttpService } from '../../../utils/axios-httpService'
import { toast } from 'react-toastify'

const Social = () => {
  const [socialAccount, setSocialAccount] = useState([])
  const [telegram, setTelegram] = useState('')
  const [x, setX] = useState('')
  const [configX, setConfigX] = useState(false)
  const [configTelegram, setConfigTelegram] = useState(false)

  const getSocial = () => {
    HttpService.getMe().then((response) => {
      setSocialAccount(response)
      setTelegram(response.data.telegramId)
      setX(response.data.twitterHandle)
    })
  }

  useEffect(() => {
    if (socialAccount.length === 0) {
      getSocial()
    }
  }, [])

  const saveSocial = async () => {
    const payload = {
      twitterHandle: x,
      telegramId: telegram
    }
    HttpService.patchUpdateSocial(payload)
      .then((response) => {
        toast.success('Social updated successfully!')
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
      })
    if (x !== '') setConfigX(false)
    if (telegram !== '') setConfigTelegram(false)
  }


  return (
    <div className="w-full flex items-start">
      <div className="flex flex-col lg:flex-row w-full lg:items-center justify-between lg:justify-start lg:gap-5">
        {/* X Account */}
        {!configTelegram &&
          <div className="flex gap-x-1">
            <div className="flex gap-x-5 items-center">
              <BsTwitterX size={26} className="text-main" />
              <span className={`font-semibold italic pr-2 min-w-20 truncate text-ellipsis ${configX ? 'hidden' : ''}`}>
                {x}
                {x === '' || !x && <span className='text-main font-bold uppercase text-sm'>Not informed</span>}
              </span>
            </div>
            <div className={`flex h-10 justify-start ${configX ? 'hidden' : ''}`}>
              <IconButton variant='text' className="h-6 w-6"
                onClick={() => {
                  setConfigX(!configX)
                  if (configTelegram) setConfigTelegram(false)
                }}
              >
                <BiEdit className="text-main h-5 w-5" />
              </IconButton>
            </div>
          </div>
        }
        {/* Telegram Account */}
        {!configX && <div className="flex gap-x-1">
          <div className="flex gap-x-5 items-center ">
            <BsTelegram size={26} className="text-main" />
            <span className={`font-semibold italic pr-2 min-w-20 truncate text-ellipsis ${configTelegram ? 'hidden' : ''}`}>
              {telegram}
              {telegram === '' || !telegram && <span className='text-main font-bold uppercase text-sm'>Not informed</span>}
            </span>
          </div>
          <div className={`flex h-10 justify-start ${configTelegram ? 'hidden' : ''}`} >
            <IconButton variant='text' className="h-6 w-6"
              onClick={() => {
                setConfigTelegram(!configTelegram)
                if (configX) setConfigX(false)
              }}
            >
              <BiEdit className="text-main h-5 w-5" />
            </IconButton>
          </div>
        </div>}


        {/* ------------ */}

        <div className={`w-full lg:w-1/3 flex gap-3  ${configX ? 'block' : 'hidden'}`}>
          <InputDefault
            placeholder='Insert your X profile'
            value={x}
            onChange={(e) => setX(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') { saveSocial() }
            }}
          />
          <Button variant='filled' color='deep-purple' className='bg-main' onClick={saveSocial}>Save</Button>
        </div>


        <div className={`w-full lg:w-1/3 flex gap-3 ${configTelegram ? 'block' : 'hidden'}`}>
          <InputDefault
            placeholder='Insert your Telegram number'
            value={telegram}
            onChange={(e) => setTelegram(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') { saveSocial() }
            }}
          />
          <Button variant='filled' color='deep-purple' className='bg-main' onClick={saveSocial}>Save</Button>
        </div>
      </div>
    </div >
  )
}

export default Social
