import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Header from './Header/HeaderAdmin'
import MainHeader from '../components/Header/Header'
import MobileSidebar from './Sidebar/MobileSidebar'
import Sidebar from './Sidebar/Sidebar'
import UserMenu from '../account/Account/User/UserMenu'
import HeaderMobile from '../components/Header/HeaderMobile'

const AdminMain = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })


  if (isMobile) {
    return (
      <div className='h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit'>
        <div className="flex flex-col w-full h-full py-5 mb-10 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
          <HeaderMobile />
          <div className='w-full h-full pt-20'>
            <Outlet />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="flex flex-col h-screen bg-bgAdmin bg-no-repeat bg-fixed bg-cover bg-left font-outfit ">
        <div className="flex overflow-hidden w-full h-full">
          <div className="w-[300px]"><Sidebar /></div>
          <div className=" flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner ">
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminMain
