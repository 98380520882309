import { Button, Card, Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { BsGear } from 'react-icons/bs'
import { FaCrown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const Guilds = ({ guilds }) => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState('')
  const [listGuilds, setListGuilds] = useState(guilds)

  const goDashboard = (guildId) => {
    localStorage.setItem('guildId', guildId)
    navigate('/dashboard')
  }

  return (
    <div className="w-full h-full flex flex-col gap-y-5 ">
      <Card className="bg-transparent shadow-none h-full items-center">
        <div className="w-full flex justify-between items-end py-2 px-5">
          <span className="text-lg font-semibold">My managed servers</span>
          <Button className="flex gap-x-1 items-center bg-main text-xs font-semibold" onClick={() => navigate('/select-server')}>
            Add New Server
            <BiPlus className="h-5 w-5" />
          </Button>
        </div>
        {listGuilds.length > 0 ? (
          <>
            <Input
              placeholder="Filter by server name"
              variant="static"
              color="deep-purple"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
              className="w-full bg-second border-none px-5 text-white"
              icon={<BiSearch className="text-xl text-main -ml-10" />}
            />
            <div className="w-full flex justify-between text-xs bg-second uppercase">
              <div className="text-start py-2 pl-5">My Servers</div>
              <div className="text-start py-2 pr-48">Actions</div>
            </div>
            <div className="max-h-[318px] w-full overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
              {listGuilds
                .filter((item) => item.isBotInstalled)
                .filter((item) => item.name.toLowerCase().includes(searchQuery))
                ?.map((guild, index) => (
                  <div
                    key={index}
                    className="flex w-full justify-between items-center bg-basic border-b border-second  overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic hover:bg-mainHover/20 transition-all duration-300"
                  >
                    <div className="flex items-center gap-x-5 w-full h-[49px]">
                      {/* ICON */}
                      <div className="pl-4">
                        {guild.icon ? (
                          <img
                            src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}`}
                            alt="Server Icon"
                            className="w-10 h-10 rounded-full shadow-dark shadow"
                          />
                        ) : (
                          <div className="w-10 h-10 rounded-full bg-second shadow-dark shadow flex items-center justify-center font-bold text-xs uppercase">
                            {guild.name.substring(0, 3)}
                          </div>
                        )}
                      </div>
                      {/* NAME */}
                      <div className="flex w-full gap-2 justify-between">
                        <div className="font-semibold px-2 w-full truncate">
                          {guild.name}
                        </div>
                        <div className="w-fit flex pr-3">
                          <span className="px-2 py-1 rounded-full bg-second font-black text-xs flex gap-x-1">
                            {guild.paidAccount ? (
                              <span className="text-xs flex items-center gap-x-1">
                                <FaCrown size={12} className="text-yellow-300" />
                                Premium
                              </span>
                            ) : (
                              <span className="text-xs">Free</span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* BUTTONS */}
                    <div className="bg-second w-px h-full"></div>
                    <div className="flex gap-x-2 items-center px-1">
                      <Button
                        variant="filled"
                        color="deep-purple"
                        className="bg-accentMain group w-32 items-center justify-center px-2"
                        disabled={!guild.isBotInstalled}
                      >
                        <span className="flex group-hover:hidden gap-x-1 items-center justify-center transition-all duration-300">
                          <BsGear />
                          Subscribe
                        </span>
                        <span className="hidden group-hover:block transition-all duration-300">
                          Coming Soon
                        </span>
                      </Button>
                      <Button
                        variant="filled"
                        color="purple"
                        className="bg-main"
                        onClick={() => goDashboard(guild.id)}
                      >
                        Dashboard
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="flex justify-center items-start h-full pt-20">
            <span className="text-sm font-semibold italic tracking-wider">
              No servers found
            </span>
          </div>
        )}
      </Card>
    </div>
  )
}

export default Guilds
