import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UserMenuMobile } from '../../account/Account/User/UserMenuMobile'
import MobileSidebar from '../../admin/Sidebar/MobileSidebar'
import logo from '../../img/200x60.png'
import avatar from '../../img/AvatarTektools.png'


const HeaderMobile = () => {
  const navigate = useNavigate()

  return (
    <header className={`flex flex-row justify-between items-center mx-auto container text-white py-3 fixed top-0 left-0 w-full shadow-xl z-30 bg-dark`}>
      {/* Sidebar Dashboard */}
      <div>
        <MobileSidebar />
      </div>

      {/* Logo Tektools */}
      <div onClick={() => navigate('/')} className='flex items-center gap-1'>
        <img src={avatar} alt='avatar tektools' className='w-auto h-10' />
        <img src={logo} alt='tektools' className='w-auto h-7' />
        <span className="text-xs text-main font-black tracking-wider bg-second px-1 py-px rounded-full mt-1 items-start h-full flex">
          BETA
        </span>
      </div>

      {/* Avatar and options user */}

      <UserMenuMobile />
    </header>
  )
}

export default HeaderMobile