import React, { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChartPieIcon } from '@heroicons/react/24/outline'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import { BiUser } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { MdOutlineWorkspacePremium } from 'react-icons/md'
import { MdSupportAgent } from 'react-icons/md'
import { handleLogout } from '../../../utils/authUtils'
import { useNavigate } from 'react-router-dom'

export const UserMenu = () => {
  const navigate = useNavigate()
  const [showDropdown, setShowDropdown] = useState(false)

  const userData = JSON.parse(localStorage.getItem('userData'))
  const selectedServer = localStorage.getItem('selectedServer')
  

  const products = [
    {
      name: 'My servers',
      description: '',
      href: selectedServer ? '/dashboard' : '/select-server',
      icon: ChartPieIcon,
    },
    {
      name: 'Pro',
      description: '',
      href: '#',
      icon: MdOutlineWorkspacePremium,
    },
    {
      name: 'Support server',
      description: '',
      href: '#',
      icon: MdSupportAgent,
    },
    {
      name: 'Docs',
      description: '',
      href: 'https://docs.tektools.app/',
      icon: CursorArrowRaysIcon,
    },
  ]

  const handleAccount = () => {
     navigate('/account')
     }

  return (
    <>
      <button
        type="button"
        className="flex items-center gap-x-4 text-sm font-semibold border-none "
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <img
          className="rounded-full h-8 lg:h-14 shadow-main shadow-sm "
          src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`}
          alt="Avatar"
        />

        <h2 className="text-lg font-medium px-3 flex items-center gap-x-1">
          {userData.user.global_name}
          <IoIosArrowDown className="h-5 w-5 flex " aria-hidden="false" />
        </h2>
      </button>
      {showDropdown && (
        <div className="absolute right-5 top-full z-30 mt-5 w-screen max-w-xs overflow-hidden rounded-3xl bg-dark shadow-lg ">
          <div className="p-4">
            {products.map((item) => (
              <div
                key={item.name}
                className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray hover:scale-105  transition duration-300"
              >
                <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg  group-hover:text-accentMain">
                  <item.icon className="h-8 w-8" aria-hidden="true" />
                </div>
                <div className="flex-auto  ">
                  <a href={item.href} className="block font-semibold">
                    {item.name}
                    <span className="absolute inset-0" />
                  </a>
                  <p className="mt-1 ">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray ">
            <button
              type="button"
              className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray hover:text-accentMain transition duration-300"
              onClick={handleAccount}
            >
              <BiUser className="h-5 w-5 flex-none " aria-hidden="true" />
              Account
            </button>
            <button
              type="button"
              className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray hover:text-red-500 transition duration-300"
              onClick={handleLogout}
            >
              <XMarkIcon className="h-5 w-5 flex-none " aria-hidden="true" />
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  )
}
