import { AiOutlineGift } from 'react-icons/ai'
import { AiFillDatabase, AiOutlineLogout, AiTwotoneUsb } from 'react-icons/ai'
import { BsArrowLeftRight } from 'react-icons/bs'
import { FiUserCheck } from 'react-icons/fi'
import {
  MdAssignmentAdd,
  MdHowToVote,
  MdLockOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineToken,
} from 'react-icons/md'
import { RiShieldUserLine } from 'react-icons/ri'

const dataSidebar = [
  {
    name: 'Anti Bot Members',
    icon: <AiTwotoneUsb />,
    link: 'antibotmembers',
    // tip: 'Consectetur voluptate veniam sunt magna mollit.',
    enable: true,
    premium: false,
  },
  {
    name: 'Holder Verification',
    icon: <FiUserCheck />,
    link: 'holderverification',
    // tip: 'Nulla excepteur Lorem ex ea commodo fugiat eu amet nisi reprehenderit esse dolor.',
    enable: true,
    premium: true,
  },
  {
    name: 'Staking Setup',
    icon: <BsArrowLeftRight />,
    link: 'stakingsetup',
    // tip: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quos.',
    enable: false,
    premium: true,
  },
  {
    name: 'SPL - Token Creation',
    icon: <MdOutlineToken />,
    link: 'tokencreation',
    // tip: 'Tip for Token Creation',
    enable: false,
    premium: true,
  },
  {
    name: 'Revenue Sharing',
    icon: <AiOutlineLogout />,
    link: 'revenuesharing',
    // tip: 'Tip for Revenue Sharing',
    enable: false,
    premium: true,
  },
  {
    name: 'Decentralized Storage',
    icon: <AiFillDatabase />,
    link: 'decentralizestorage',

    // tip: 'Tip for Decentralized Store',
    enable: false,
    premium: true,
  },
  {
    name: 'Voting System',
    icon: <MdHowToVote />,
    link: 'votingsystem',
    // tip: 'Tip for Voting System',
    enable: false,
    premium: false,
  },
  {
    name: 'Password Lock',
    icon: <MdLockOutline />,
    link: 'passwordlock',
    // tip: 'Tip for Password Lock',
    enable: false,
    premium: false,
  },
  {
    name: 'Wallet Management',
    icon: <MdOutlineAccountBalanceWallet />,
    link: 'walletmanagement',
    // tip: 'Tip for Wallet Management',
    enable: false,
    premium: true,
  },
  {
    name: 'Ticket System',
    icon: <MdAssignmentAdd />,
    link: 'ticket',
    // tip: 'Tip for Ticket System',
    enable: true,
    premium: false,
  },
  {
    name: 'Giveaway',
    icon: <AiOutlineGift />,
    link: 'giveaway',
    enable: true,
    premium: false,
  },
  {
    name: 'Role System',
    icon: <RiShieldUserLine />,
    link: 'rolesystem',
    enable: false,
    premium: false,
  },
]

export default dataSidebar
