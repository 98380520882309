import React, { useState, useEffect } from 'react'
import Servers from './Guild'
import Social from './Social'
import Wallets from './Wallets'
import { HttpService } from '../../../utils/axios-httpService'

const SetupAccount = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [listGuilds, setListGuilds] = useState([])

  useEffect(() => {
    if (listGuilds && listGuilds.length > 0) return
    HttpService.getGuilds()
      .then((response) => {
        setListGuilds(response.data)
      })
      .catch((error) => {
        console.error('Error fetching guilds:', error)
      })
  }, [])

  return (
    <section className="w-full h-full flex flex-col mx-auto container gap-5">
      <div className="flex flex-col w-full items-start gap-5">
        <div className="flex flex-col lg:flex-row w-full relative pb-5 items-center justify-center">
          {/* <div className="absolute top-0 left-0 w-[90px] lg:w-[170px] h-full bg-main/30 rounded-t-full"></div> */}
          <img
            className="rounded-full h-42 lg:h-40 lg:pl-2 pt-1"
            src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`}
            alt="Avatar"
          />
          <div className="flex flex-col gap-y-1 w-full h-full lg:justify-end items-center">
            <h2 className="text-2xl lg:text-4xl font-semibold lg:ml-[6px] lg:pl-2 flex items-center gap-x-1 bg-none rounded-tr-full lg:-mb-5 lg:py-5 py-2 ">
              {userData.user.global_name}
            </h2>
          </div>
        </div>
        <Social />
      </div>
      <div className={` flex flex-col-reverse lg:flex-row gap-5 h-full mt-14 justify-center`}>
        {/* lista de servidores com bot aqui */}
        { listGuilds.length > 0 && <Servers guilds={listGuilds} />}
        <hr className="w-full border-basic lg:hidden" />
        <div className={`${listGuilds.length > 0 ? 'w-full' : 'w-2/3 justify-center items-center'}`}>
          {/* lista de wallets aqui */}
          <Wallets />
        </div>
      </div>
    </section>
  )
}

export default SetupAccount
