import React from 'react'
import ServerCard from './_components/ServerCard'
import AdBanner from './_components/AdBanner'
import AdBannerMobile from './_components/AdBannerMobile'
import { useMediaQuery } from 'react-responsive'


const ChooseServer = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <>
      <section className="h-full w-full text-white flex flex-col py-10 gap-y-10">
        {isMobile ? 
        <div className='bg-banner w-full flex justify-center'>
          <AdBannerMobile />
        </div> :
          <div className='fixed z-20 bg-banner'>
            <AdBanner />
          </div>
        }
        <h1 className={`font-medium text-4xl flex ${isMobile ? '' : 'mt-40'}`}>Select a server</h1>
        <ServerCard />
      </section>
    </>
  )
}

export default ChooseServer
