import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChartPieIcon } from '@heroicons/react/24/outline'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiUser } from 'react-icons/bi'
import { IoIosArrowDown } from 'react-icons/io'
import { MdOutlineWorkspacePremium } from 'react-icons/md'
import { MdSupportAgent } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import avatar from '../../../img/TektoolsFaceWhite.png'
import { handleLogout } from '../../../utils/authUtils'


export const UserMenuMobile = () => {
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [showDropdown, setShowDropdown] = useState(false)
  const guildId = localStorage.getItem('guildId')

  const menu = [
    {
      name: 'My servers',
      description: '',
      href: guildId ? '/dashboard' : '/select-server',
      icon: ChartPieIcon,
    },
    {
      name: 'Pro',
      description: '',
      href: '#',
      icon: MdOutlineWorkspacePremium,
    },
    {
      name: 'Support server',
      description: '',
      href: '#',
      icon: MdSupportAgent,
    },
    {
      name: 'Docs',
      description: '',
      href: 'https://docs.tektools.app/',
      icon: CursorArrowRaysIcon,
    },
  ]

  return (
    <div className=''>
      <Button onClick={() => setShowDropdown(!showDropdown)} variant='filled'  className='flex gap-1 items-center rounded-full p-0'>
        {userData?.user.avatar ?
          <img src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`} alt='avatar tektools' className=' w-12 h-12 rounded-full' />
          :
          <img src={avatar} alt='avatar tektools' className=' w-12 h-12 rounded-full' />
        }
        <div className='flex items-start h-full px-1'>
          <IoIosArrowDown 
            size={20} 
            className={`transform transition-transform duration-300 text-main ${showDropdown ? 'rotate-180' : ''} `} 
          />
        </div>
      </Button>
      <div>
        {showDropdown && (
          <div className="absolute right-1 top-12 z-30 mt-5 w-screen max-w-xs overflow-hidden rounded-bl-lg bg-dark shadow-lg ">
            <div className="p-4">
              {menu.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray hover:scale-105  transition duration-300"
                >
                  <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg  group-hover:text-accentMain">
                    <item.icon className="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div className="flex-auto  ">
                    <a href={item.href} className="block font-semibold">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 ">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray ">
              <button
                type="button"
                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray hover:text-accentMain transition duration-300"
                onClick={() => navigate('/account')}
              >
                <BiUser className="h-5 w-5 flex-none " aria-hidden="true" />
                Account
              </button>
              <button
                type="button"
                className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray hover:text-red-500 transition duration-300"
                onClick={handleLogout}
              >
                <XMarkIcon className="h-5 w-5 flex-none " aria-hidden="true" />
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
